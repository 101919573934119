* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  background-color: #f9fafb;
}

a {
  text-decoration: none;
}

.snackbar-root-rounded > div {
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
}

body::-webkit-scrollbar,
body *::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: rgba(145, 158, 171, 0.24);
}

body::-webkit-scrollbar-thumb,
body ::-webkit-scrollbar-thumb {
  border: none;
  border-radius: 8px;
  background-color: rgba(99, 115, 129, 0.48);
}

body::-webkit-scrollbar-thumb:hover,
body ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(99, 115, 129, 0.88);
}
