body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f7fa;
  color: #333;
}

.calendar-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 5;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: transparent;
  /* Fallback color */
  // background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.calendar {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -78px;
  margin-left: -142px;
  padding: 15px;
  border-radius: 12px;
  overflow: hidden;
  width: 330px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  color: rgba(0, 0, 0, 0.8);
}

.calendar--nav {
  margin: -15px -15px 5px;
  padding: 0 15px;
  background-color: #fff;
  color: #1e1856;
  height: 50px;
  position: relative;
}

.calendar--nav a {
  position: absolute;
  cursor: pointer;
  left: 10px;
  font-size: 30px;
  line-height: 1;
  top: 16px;
  width: 30px;
  text-align: center;
  display: inline-block;
  color: rgba(0, 0, 0, 0.3);
  user-select: none;
}

.calendar--nav a:last-child {
  left: auto;
  right: 10px;
}

.calendar--nav h1 {
  color: #37474f;
  margin: 0;
  position: absolute;
  left: 40px;
  right: 40px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 66px;
  user-select: none;
}

.calendar--days {
  font-size: 0;
}

.calendar--days span {
  width: 12.58571%;
  display: inline-block;
  text-align: center;
  user-select: none;
  cursor: pointer;
  margin: 4px 0;
  line-height: 37px;
  position: relative;
  font-size: 12px;
}

.calendar--days span.label {
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.3);
  font-size: 11px;
  cursor: initial;
}

.calendar--days span.active {
  font-weight: 700;
  background-color: rgba(182, 112, 244, 0.05);
  border-radius: 12px;
}

.calendar--days span.muted {
  color: rgba(0, 0, 0, 0.3);
}

.calendar--days span.between {
  border-radius: 0;
}

.calendar--days span.start,
.calendar--days span.between,
.calendar--days span.end {
  background-color: #0767db;
  color: #fff;
}

.calendar--days span.start {
  border-radius: 25px 0 0 25px;
}

.calendar--days span.end {
  border-radius: 0 25px 25px 0;
}

.calendar--days span.start.end {
  border-radius: 25px;
}

.calendar--days span.between:nth-child(7n):after,
.calendar--days span.start:nth-child(7n):after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  background-color: #0767db;
  width: 20px;
}

.calendar--days span.between:nth-child(7n + 1):after,
.calendar--days span.end:nth-child(7n + 1):after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  background-color: #0767db;
  width: 20px;
}

.calendar--days span.start.end:after {
  display: none;
}

.calendar-text {
  margin-top: 15px !important;
  margin-right: 1% !important;
  margin-left: 1% !important;
  color: grey;
}

.calendar--days .disabled {
  pointer-events: none;
  color: #ccc;
  /* Grey out the disabled dates */
}