.course-item {
  display: flex;
  align-items: center;
  margin: 2%;

  &-name {
    font-size: 22px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    line-height: 28px;
    margin-left: 1%;
    margin-right: 1.5%;
  }

  &-color {
    min-width: 19px;
    height: 23px;
    border-radius: 7px;
  }
}

.allcourses-tutor {
  background-color: transparent !important;
  box-shadow: 0 0 2px 0px rgb(145 158 171 / 24%),
    0 16px 64px -4px rgb(145 158 171 / 24%) !important;
}

.allcourses {
  background-color: transparent !important;
}

.course-items {
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%),
    0 16px 32px -4px rgb(145 158 171 / 24%);
}

.modal {

  // &-heading{
  //   padding: 30px 30px !important;
  // }
  &-heading {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
    padding: 40px 30px;
    display: flex;
  }

  &-heading-confirmation {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #262626;
    padding: 40px 30px;
    display: flex;
  }

  &-description {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #546e7a;
  }

  &-description-confirmation {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #546e7a;
  }

  @media (max-width: 767px) {
    &-heading {
      padding: 60px 30px;
    }

    &-heading-confirmation {
      padding: 50px 30px;
    }

    &-description-confirmation {
      padding: 0% 10%;
      line-height: 20px;
    }
  }

  &-button-add {
    width: 136px !important;
    border: 2px solid #1d615a !important;
    color: #1d615a !important;
  }

  &-button-add-confirmation {
    width: 136px !important;
    border: 2px solid #1d615a !important;
    color: #1d615a !important;
  }

  &-button-remove {
    width: 136px !important;
    border: 2px solid red !important;
    color: red !important;
  }

  &-button-remove-confirmation {
    width: 136px !important;
    border: 2px solid red !important;
    color: red !important;
  }

  &-button-cancel {
    width: 136px !important;
    background-color: #afafaf !important;
    color: #ffffff !important;
  }

  &-select {
    padding: 7% 13%;
    display: flex;
    justify-content: space-between;
  }

  &-select-confirmation {
    padding-bottom: 2%;
    padding-top: 12%;
    padding-left: 13%;
    padding-right: 13%;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    &-select {
      padding: 7% 5%;
    }

    &-select-confirmation {
      padding-top: 16%;
    }

    &-button-cancel {
      width: 114px !important;
    }

    &-button-remove-confirmation {
      width: 114px !important;
    }

    &-button-add-confirmation {
      width: 114px !important;
    }
  }
}

.token-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  &-count {
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    word-spacing: -5px;

    &-minus {
      margin: auto;
      cursor: pointer;

      &-disabled {
        margin: auto;
        cursor: not-allowed;

        polygon {
          fill: #d3d3d3 !important;
        }
      }
    }

    &-span {
      font-size: 32px;
      font-weight: bold;
      color: #262626;
      margin-left: 56px;
      margin-right: 56px;
      cursor: pointer;
      width: 82px;
    }

    &-plus {
      margin: auto;
      cursor: pointer;

      &-disabled {
        margin: auto;
        cursor: not-allowed;

        polygon {
          fill: #d2d2d2 !important;
        }
      }
    }
  }

  &-values {
    display: flex;
    margin: auto;

    &-square {
      width: 72px;
      height: 72px;
      border-radius: 4px;
      border: solid 1px #d2dfde;
      display: flex;
      margin-top: 48px;
      margin-left: 16px;
      cursor: pointer;

      &-span {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #262626;
        margin: auto;
        word-spacing: -5px;
      }
    }

    .selected {
      border: 1px solid black !important;
    }
  }

  &-input {
    width: 400px;
    margin: 4% 17% 0% !important;
  }

  @media (max-width: 767px) {
    &-input {
      width: 334px;
      margin: 4% 5% !important;
    }
  }
}

.date-range-start {
  .MuiInputLabel-formControl {
    position: relative !important;
    z-index: 0;
  }

  .MuiFilledInput-adornedEnd {
    margin-top: 9px;
  }

  .MuiInputBase-formControl {
    margin-top: 9px;
  }
}

.date-range-end {
  .MuiInputLabel-formControl {
    position: relative !important;
    z-index: 0;
  }

  .MuiFilledInput-adornedEnd {
    margin-top: 9px;
  }

  .MuiInputBase-formControl {
    margin-top: 9px;
  }
}

.tokens-add-action {
  font-size: 16px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 20px;
  padding: 0px 20px;
  cursor: pointer;
}

.period-card {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;

  &-left {
    font-size: 20px;
    font-weight: bold;
    padding: 18px 22px;
    padding-left: 30px;
    background-color: #2686eb;
    color: #ffffff;
    border-radius: 15px 0px 0px 15px;
    cursor: pointer;
  }

  &-middle {
    font-size: 20px;
    font-weight: bold;
    padding: 18px 22px;
    background-color: #2686eb;
    color: #ffffff;
    margin: 0px 1px;
    cursor: pointer;
  }

  &-right {
    font-size: 20px;
    font-weight: bold;
    padding: 18px 25px;
    padding-right: 38px;
    background-color: #2686eb;
    color: #ffffff;
    border-radius: 0px 15px 15px 0px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    &-right {
      font-size: 18px;
      padding: 12px 22px;
      padding-right: 23px;
    }

    &-left {
      font-size: 18px;
      padding: 12px 15px;
      padding-left: 23px;
    }

    &-middle {
      font-size: 18px;
      padding: 12px 22px;
    }
  }
}

.subscription-plans {
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    display: block;
  }
}

.groupsession-subscription-plan {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.plan-card {
  border: 1px solid black;
  min-width: 250px;
  border-radius: 40px;

  @media (max-width: 767px) {
    margin: auto;
    margin-top: 10%;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-monthly-container {
    margin: 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-monthly {
    font-size: 20px;
    font-weight: bold;
    padding: 15px 15px 0px 4px;
  }

  &-month {
    font-size: 20px;
    font-weight: bold;
    margin: 9% 1% 15% 14%;
    margin: 20% 0;
    display: flex;
    justify-content: center;
  }

  &-price-container {
    margin: 10% 0;
    display: flex;
    justify-content: center;

    &-currency {
      font-size: 16px;
    }

    &-total {
      font-size: 26px;
      font-weight: bold;
      padding: 15px 15px 0px 4px;
    }

    &-tag {
      font-size: 16px;
      padding-top: 15px;
    }
  }

  &-off {
    color: #2883e9;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-top: 3%;
    padding-inline: 8%;
    line-height: 18px;
    text-align: center;
  }

  &-promotion-code {
    color: #2883e9;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  &-save {
    color: #2883e9;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  &-divider {
    margin: 12% 0% !important;
  }

  &-content {
    display: flex;
    margin-bottom: 4%;
    margin-left: 7%;

    &-first {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    margin: 12% 0;
    cursor: pointer;

    &-text {
      font-size: 12px;
      background: #2686eb;
      color: #ffffff;
      padding: 4% 8%;
      font-weight: bold;
      border-radius: 3px;
    }

    &-disabled {
      font-size: 12px;
      border: solid 1px #d9d9d9;
      color: #afafaf;
      background: transparent;
      padding: 4% 8%;
      font-weight: bold;
      border-radius: 3px;
    }
  }
}

.userpill-container {
  display: flex;

  &-left {
    min-height: 39px;
    font-size: 16px;
    min-width: 113px;
    padding: 12px 22px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    border-radius: 7px 0px 0px 7px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-left: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;

    &-selected {
      min-height: 39px;
      font-size: 16px;
      min-width: 113px;
      padding: 12px 22px;
      display: flex;
      cursor: pointer;
      color: #ffffff;
      justify-content: center;
      border-radius: 7px 0px 0px 7px;
      background: #1a53ae;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }

  &-middle {
    min-height: 39px;
    font-size: 16px;
    min-width: 113px;
    padding: 12px 22px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    background: #1a53ae;
    justify-content: center;
    // border: 1px solid #E4E4E4;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &-selected {
      min-height: 39px;
      font-size: 16px;
      min-width: 113px;
      padding: 12px 22px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      border-top: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      box-shadow: none;
    }
  }

  &-right {
    min-height: 39px;
    font-size: 16px;
    min-width: 113px;
    padding: 12px 22px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 0px 7px 7px 0px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    border-right: 1px solid #e4e4e4;

    &-selected {
      min-height: 39px;
      font-size: 16px;
      min-width: 113px;
      padding: 12px 22px;
      display: flex;
      cursor: pointer;
      color: #ffffff;
      justify-content: center;
      border-radius: 0px 7px 7px 0px;
      background: #1a53ae;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}

.button-paid {
  background: #5286c3;
  min-width: 110px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 6px;
}

.button-paid-selected {
  min-width: 150px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 6px;
}

.button-paid-header {
  display: none !important;
}

.policy-text {
  color: #0767db;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.currencyDropdown {
  .MuiInputBase-root {
    max-height: 200px !important;
  }

  .MuiList-root {
    max-height: 200px !important;
  }

  .MuiMenu-paper {
    max-height: 200px !important;
  }
}

.button-paid-updating {
  background: transparent;
  color: rgb(120, 120, 120);
  border: solid 2px rgb(120, 120, 120);
}

.button-paid-done {
  background-color: #787878;
  color: #ffffff;
}

.button-paid-undone {
  background-color: #5286c3;
  color: #ffffff;
}

.pdf-container {
  padding: 0;
  min-width: 378px;
  overflow-y: scroll;
  // height: 64vh;
}

.scrollable-container {
  max-height: 50vh;
  overflow-y: scroll;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-container {
  -ms-overflow-style: none;
  /* IE and Edge */
}